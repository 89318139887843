// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout";
import { graphql, Link, useStaticQuery } from "gatsby";
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_rentabilidades.jpg";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import FondosChart from "../../components/fondos_chart/fondos_chart";
import "./fondos.css"
import faqjson from "../../misc/fondos_related/faq.json";
import slick_items from "../../misc/fondos_related/slick_items_fondos.json";
import Slider from "react-slick";
import {SalWrapper} from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";


let text = `Creemos que existen tantas inversiones como inversores, <br /><span class='highlightsbold'> te presentamos nuestros Fondos de inversión.</span>`;

const FondosPage = () => {
  //const [mobile, setMobile] = useState(false);
    const [xs, setXs] = useState(false);

    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 4}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);
    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    const pageTitle = 'Invertí en Fondos Comunes de Inversión';
    const pageDesc = 'En Balanz te ofrecemos la más amplia oferta de Fondos de Inversión, para diferentes necesidades y perfiles de riesgo. Conocé las Ventajas de un FCI.';


    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

   /* useEffect(() => {

    }, [suscription]);*/



  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: true,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };

  const slickBonosSettings = {
        centerMode: false,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 576,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/4")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })
                })
                setFaq((old) => ({...old, list: data}));

            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
        <Helmet>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué es un Fondo Común de Inversión?",
                        "description": "Un Fondo Común de Inversión es un vehículo mediante el cual un grupo de personas.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=Gp597Bzpf1I",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/fondos/",
                        "embedUrl": "https://balanz.com/inversiones/fondos/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en Fondos de Inversión?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=olT8KcsCMzw&feature=emb_title",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/fondos/",
                        "embedUrl": "https://balanz.com/inversiones/fondos/"
                    }
                `}
            </script>
        </Helmet>
        <ProductBanner
          bannerContainerClassName="bannerFondos"
          bannerTrasparecy="bannerMask"
          productTitle="Fondos de Inversión"
          bannerImage={Banner}
          bannerImageClassName="fondosBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
        <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué es un Fondo Común de Inversión?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Un Fondo Común de Inversión es un vehículo  mediante el cual un grupo de personas, con un similar perfil y objetivo de inversión, aportan su dinero para que un profesional lo administre, invirtiendo en una cartera diversificada de activos.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button id="fondos_invertir_1" variant="secondary" text="Invertir en Fondos" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container
                data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="200"
            >
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/Gp597Bzpf1I"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="fullwidth razones fondos-razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 razones para invertir en Fondos</h2>

                 <SlickProduct
                   slickSettings={slickSettings}
                   list={slick_items}
                 />

                    <div className="text-center mt-5 pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5"
                          data-sal="fade"
                          data-sal-delay="200"
                          data-sal-duration="200"
                    >
                         <Link to="/inversiones/fondos/rentabilidades-fci">
                         <Button variant="secondary" text="Nuestros Fondos"/>
                         </Link>
                    </div>
                </Row>
             </Container>
         </section>

        <section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Fondos de Inversión?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br />Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button id="fondos_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir en Fondos" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-start">
                    <IframeVIdeo
                      src="https://www.youtube.com/embed/olT8KcsCMzw"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 {/*<Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>*/}
             </Container>
        </section>


        <section className="fullwidth tipos-fondos"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
            <Container>
                <Row>
                    <h2>Tipos de Fondos de Inversión</h2>
                    <Slider className="slick-tipos-fondos" {...slickBonosSettings}>
                        <Col xs={12} md={6} className="slick-item-container">
                            <h3 className="block-quote bg-lt-grey p-4">Fondos<br />Transaccionales</h3>
                            <p className="base-text-0 py-5 ">Son una inversión muy conservadora y apropiada para quienes quieren invertir su dinero a corto plazo, con disponibilidad del capital de manera inmediata.
                            </p>
                        </Col>
                        <Col xs={12} md={6} className="slick-item-container">
                            <h3 className="block-quote bg-lt-grey p-4">Fondos<br />Renta Fija</h3>
                            <p className="base-text-0 py-5">Enfocados en estrategias de inversión a mediano y/o largo plazo. Estos fondos plantean
                                estrategias que buscan rendimientos y fluctuaciones de precio moderados.</p>
                        </Col>
                        <Col xs={12} md={6} className="slick-item-container">
                            <h3 className="block-quote bg-lt-grey p-4">Fondos<br />Renta Variable</h3>
                            <p className="base-text-0 py-5">Enfocados en estrategias de inversión a largo plazo. Persiguen rendimientos potencialmente elevados y están sujetos a los movimientos de precios de los mercados bursátiles.</p>
                        </Col>
                        {/*<Col xs={12} md={6} className="slick-item-container">
                            <h3 className="block-quote bg-lt-grey p-4">Fondos<br />Mixtos</h3>
                            <p className="base-text-0 py-5">Combinan distintos activos financieros: bonos, acciones, deuda corporativa y más. Su riesgo y rendimiento dependerán del comportamiento de cada uno de ellos. Enfocado en perfiles de inversión agresivos.</p>
                        </Col>*/}
                    </Slider>
                </Row>
            </Container>
        </section>

        <section className="fullwidth tipos-fondos"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
            <Container>
                <Row>
                    <h2>Fondos Destacados</h2><br/><br/>
                    <Col xs={12} className="">
                        <FondosChart />
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Fondos Comunes de Inversión? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>               
        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'fondos-faq'} />
                </Row>
              </Container>
        </section>
        </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"fondos"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default FondosPage;
