import React, {useRef, useEffect, useState} from "react";
//import Image from "../image/image"
import { Link } from "gatsby";
import "./fondos-chart.css"
import TriangleLeft from "../../imgs/triangle-left.svg";
import TriangleRight from "../../imgs/triangle-right.svg";

export const FondosChart = () => {
    const [suscription, setSuscription] = useState(1);
    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);
    const [wwith, setWwith] = useState(0);


    //Slide click
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift)
        // console.log('scrl.current.scrollLeft', scrl.current.scrollLeft, 'scrollX',scrollX, 'shift',shift, 'scrl.current.scrollWidth',scrl.current.scrollWidth, 'scrl.current.scrollLeft',scrl.current.scrollLeft, 'scrl.current.offsetWidth', scrl.current.offsetWidth)

        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
            // console.log('setscrolEnd', scrolEnd)
        } else {
            setscrolEnd(false);
            // console.log('setscrolEnd', scrolEnd)
        }
    };


    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const handleChangePill = (e, newval) => {
        e.preventDefault();
        setSuscription(newval);
    };

    useEffect(()=>{
        const handleWwindow = () => {
            let chartbox = document.querySelector('.chart-wrapper');

            let newwidth = chartbox.offsetWidth
            setWwith(newwidth);

            slide(-wwith);
            scrollCheck();
        };

        document.addEventListener('resize', handleWwindow);

        handleWwindow();

        return () => {
            // clean up the event handler when the component unmounts
            document.removeEventListener("resize", handleWwindow);
        };
    }, []);


    return (
      <>
            <div className="currency-selector d-flex flex-row gx-3">
                <label>Suscripción</label>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a className={`nav-link ${suscription === 1 ? 'active' : ''}`} aria-current="page" href="#" onClick={(e) => handleChangePill(e, 1)}>Pesos</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${suscription === 2 ? 'active' : ''}`} aria-current="page" href="#" onClick={(e) => handleChangePill(e, 2)}>Pesos con destino específico</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${suscription === 3 ? 'active' : ''}`} href="#" onClick={(e) => handleChangePill(e, 3)}>Dólares</a>
                    </li>
                </ul>
            </div>
            <div className="fondos-chart-holder">
    <span className="axis-name axis-y">Retorno</span>
    <div className="axis">
      <div className="chart-wrapper" ref={scrl} onScroll={scrollCheck}>
        <div className="fondos-chart" >
          <Link to="/inversiones/fondos/money-market" className={`item-fondo item1 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Money<br />Market Pesos
            </span>
            <br />
            T+0
          </Link>
          <Link to="/inversiones/fondos/performance-ii/"  disabled={true} className={`item-fondo item17 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Perfomance II 
             (Lecaps)
             </span><br />
            T+0
          </Link>
          <Link to="/inversiones/fondos/ahorro" className={`item-fondo item2 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Ahorro</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/performance-iii" className={`item-fondo item11 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Performance III Corporativo</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/renta-fija-dolar-linked" className={`item-fondo item3 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Renta Fija<br />(Dólar <br /> Linked)</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/institucional-inflation-linked" className={`item-fondo item4 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Institucional<br />(Inflation <br /> Linked)</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/renta-fija-opportunity" className={`item-fondo item5 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Renta Fija<br />Opportunity</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/retorno-total" className={`item-fondo item6 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Retorno<br /> Total</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/acciones" className={`item-fondo item7 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Acciones</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/equity-selection" className={`item-fondo item12 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Equity Selection</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/abierto-pymes" className={`item-fondo item14 dolar ${suscription === 2 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>
            Abierto PyMES</span><br/>
            T+1
          </Link>
          <Link to="/inversiones/fondos/desarrollo" className={`item-fondo item15 dolar ${suscription === 2 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Desarollo</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/crecimiento/" className={`item-fondo item19 dolar ${suscription === 2 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Crecimiento</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/abierto-infraestructura" className={`item-fondo item16 dolar ${suscription === 2 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Abierto Infrestructura</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/sudamericano" className={`item-fondo item8 dolar ${suscription === 3 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Sudamericano</span><br />
            T+2
          </Link>
          <Link to="/inversiones/fondos/money-market-dolares" className={`item-fondo item20 dolar ${suscription === 3 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Money<br />Market USD
            </span>
            <br />
            T+0
          </Link>
          <Link to="/inversiones/fondos/estrategia-i" className={`item-fondo  item9 dolar ${suscription === 3 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Estrategia I</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/ahorro-en-dolares" className={`item-fondo item10 dolar ${suscription === 3 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Ahorro USD<br />(Corporativo)</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/estrategia-iii" className={`item-fondo item21 dolar ${suscription === 3 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Estrategia III USD  <br />(Latam)</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/renta-fija-en-dolares" className={`item-fondo item13 dolar ${suscription === 3 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Renta Fija USD</span><br />
            T+1
          </Link>
          <Link to="/inversiones/fondos/soja/"  disabled={true} className={`item-fondo item18 pesos ${suscription === 1 ? 'd-block': 'd-none'}`} >
            Balanz<br />
            <span>Soja<br /></span><br />
            T+1
          </Link>
        </div>
      </div>
    </div>
    <span className="axis-name axis-x">Riesgo</span>
  </div>

          <div className="d-flex w-100 justify-content-between">
              {scrollX !== 0 && (
                  <div className="triangle-btn align-self-start me-auto" onClick={() => slide(-wwith)}>
                      <img src={TriangleLeft} alt="Anterior" className="next-tri" />
                  </div>
              )}
              {!scrolEnd && (
                  <div className="triangle-btn align-items-end ms-auto" onClick={() => slide(+wwith)}>
                      <img src={TriangleRight} alt="Siguiente" className="next-tri" />
                  </div>
              )}
          </div>
      </>
    );
};



export default FondosChart;